import {
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  TextField,
  IconButton,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { responsiveStyles } from "../assets/css/generalStyling";
import BrikittIcon from "../assets/images/Brikitt.png";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import searchIcon from "../assets/icons/search.png";
import LocationIcon from "../assets/icons/location.png";
import LeftIcon from "../assets/icons/leftIcon.png";
import RightIcon from "../assets/icons/rightIcon.png";
import BriksCoin from "../assets/icons/Coin.png";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import MuiDateRangePickerDay from "@mui/lab/DateRangePickerDay";
import { styled } from "@mui/material/styles";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import StaticDateRangePicker from "@mui/lab/StaticDateRangePicker";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import Modal from "../component/Modal";
import Add from "../assets/icons/Add.png";
import LessIcon from "../assets/icons/less.png";
import Carousel from "../component/Carousel";
import "./css/booking.css";
import SearchBooking from "./SearchBooking";
import CustomSearchComponent from './CustomSearchComponent';
import ResponsiveCustomSearchComponent from './mobileCustomSearchComponent';
import Bookingbackground from "../assets/images/Ellipse 446.png";
import { useDispatch, useSelector } from "react-redux";
import {
  bookingCheckout,
  getBookings,
  getBriks,
  getSearchBriks,
} from "../store/actions/bookingActions";
import { Close } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useGeneralProvider } from "../providers/GeneralProvider";
import clsx from "clsx";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../assets/css/datepicker.css";
import BookingEmptyState from "../assets/images/booking_empty.svg";
import { useMediaQuery } from "react-responsive";
import { Select, MenuItem, FormHelperText, FormControl, InputLabel } from '@material-ui/core';
import { useCallback } from "react";
import { values } from "lodash";

const MyBookingCard = React.lazy(() => import("./component/MyBookingCard"));

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& .MuiAutocomplete-listbox": {
        display: "flex",
        // border: "1px solid grey",
        // color: "green",
        // fontSize: 18,
      },
    },
  })
);

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const renderWeekPickerDay = (selectedValue, dateRangePickerDayProps) => {
    return <DateRangePickerDay {...dateRangePickerDayProps} />;
  };

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const DateRangePickerDay = styled(MuiDateRangePickerDay)(
    ({
      theme,
      isHighlighting,
      isStartOfHighlighting,
      isEndOfHighlighting,
    }) => ({
      ...(isHighlighting && {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        "&:hover, &:focus": {
          backgroundColor: theme.palette.primary.dark,
        },
      }),
      ...(isStartOfHighlighting && {
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
      }),
      ...(isEndOfHighlighting && {
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
      }),
    })
  );

  return (
    <Dialog onClose={handleClose} open={open}>
      {/* <DialogTitle>Set backup account</DialogTitle> */}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDateRangePicker
          displayStaticWrapperAs="desktop"
          label="date range"
          value={selectedValue}
          onChange={props.onChange}
          renderDay={renderWeekPickerDay}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField {...startProps} />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField {...endProps} />
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
    </Dialog>
  );
}

const CustomPopper = function (props) {
  const classes = useStyles();
  return (
    <>
      <Popper {...props} className={classes.root} placement="bottom" />
    </>
  );
};

function Booking() {
  const isExtraSmallMobileDevice = useMediaQuery({
    // query: "(max-device-width: 480px)"
    minWidth: 0,
    maxWidth: 480,
  });

  const isMobileDevice = useMediaQuery({
    // query: "(min-device-width: 480px)"

    minWidth: 481,
    maxWidth: 768,
  });

  //3550  ..double
  // 1450+90 =>2600
  const isTabletDevice = useMediaQuery({
    // query: "(min-device-width: 768px) and (min-device-width: 1023px)"
    minWidth: 769,
    maxWidth: 1024,
  });

  const isLaptop = useMediaQuery({
    // query: "(min-device-width: 1024px) and (max-device-width: 1199px)"
    minWidth: 1025,
    maxWidth: 1200,
  });

  const isDesktop = useMediaQuery({
    // query: "(min-device-width: 1200px)"
    minWidth: 1201,
  });

  const responsive = responsiveStyles();
  const [tab, setTab] = useState("graph");
  const [value, setValue] = React.useState("my_properties");
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const general = useGeneralProvider();
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [location, setLocation] = useState("");
  const [selectedValue, setSelectedValue] = React.useState([
    moment(),
    moment().add(2, "days"),
  ]);
  const [modal, setModal] = useState(false);
  const [search, setSearch] = useState(false);
  const {
    briks,
    searchBriks,
    bookingCheckout: { brik, dates, members },
    get_bookings,
  } = useSelector((state) => state.booking);

  const [adults, setAdults] = useState(members.adults);
  const [childrens, setChildrens] = useState(members.kids);
  const [infants, setInfants] = useState(members.infants);
  const [pets, setPets] = useState(members.pets);
  // Getting briks data for dropdown
  const [briksArr, setBriksArr] = useState([]);
  const [brikPropertyNameArr, setBrikPropertyNameArr] = useState([]);
  const [filter, setFilter] = useState({
    date_check_in: moment(selectedValue[0]).format(
      "YYYYMMDD"
    ),
    date_check_out: moment(selectedValue[1]).format(
      "YYYYMMDD"
    ),
    is_self: value == 'my_properties' ? true : false,
    q: "all"
  })

  //Dropdown value selection usestate
  const [selectedBrik, setSelectedBrik] = useState('');

  useEffect(() => {
    dispatch(getSearchBriks(filter));
   
    
    // if (briksArr?.length == 0 || briksArr?.length!=brikPropertyNameArr?.length) {
    //   let briksHtmlStringArr = []
    //   let brikPropertyNames = []
    //   dispatch(getSearchBriks(filter));
    //   for (let i = 0; i < searchBriks.length; i++) {
    //     brikPropertyNames.push(searchBriks[i]?.property_name)
    //     briksHtmlStringArr.push(<MenuItem value={i + 1}>{searchBriks[i]?.property_name}</MenuItem>)
    //   }
    //   setBriksArr(briksHtmlStringArr)
    //   setBrikPropertyNameArr(brikPropertyNames)
    // }
  }, []);

  const handleChange = useCallback(async(event) => {
    setValue(event.target.value);
    const filters = {
      ...filter,
      is_self: event?.target?.value==="my_properties" ? true : false
    }
    setFilter(filters)
    dispatch(getSearchBriks(filters));
  }, [setFilter, dispatch]);

  // useEffect(() => {
  //   let briksHtmlStringArr = []
  //   let brikPropertyNames = []
  //   for (let i = 0; i < searchBriks.length; i++) {
  //     brikPropertyNames.push(searchBriks[i]?.property_name)
  //     briksHtmlStringArr.push(<MenuItem value={i + 1}>{searchBriks[i]?.property_name}</MenuItem>)
  //   }
  //   setBriksArr(briksHtmlStringArr)
  //   setBrikPropertyNameArr(brikPropertyNames)
  // }, [searchBriks, setBriksArr, setBrikPropertyNameArr]);

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const handleClickOpen = () => {
    // setOpen(true);
    setDatePickerModal(true);
  };

  const get_bookingsMemonize = React.useMemo(() => {
    return get_bookings;
  }, [get_bookings]);
const [selectedBrikValue, setSelectedBrikValue] = useState("");

const handleSelectedBrik = (brik) => {
  setSelectedBrikValue(brik);
};

  useEffect(() => {
    console.log("Booking Component USEFEECT");
    general.setHeaderComponent(() => {
      return (
        <Breadcrumbs style={{ fontSize: "14px" }} aria-label="breadcrumb">
          <Link
            style={{ textDecoration: "none", cursor: "pointer" }}
            href="/"
            color="inherit"
          >
            Home
          </Link>
          <Link
            style={{ textDecoration: "none", cursor: "pointer" }}
            href="/booking"
            color="inherit"
          >
            Booking
          </Link>
        </Breadcrumbs>
      );
    });
  }, []);

  useEffect(() => {
    if (get_bookings.no_booking == 0) dispatch(getBookings());
  
  }, [get_bookings.no_booking]);

  const ModalHeader = () => {
    return (
      <Box
        padding="0px 14px"
        display="flex"
        style={{
          zIndex: 1,
          color: "black",
          paddingTop: "10px",
          fontWeight: "600",
        }}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>Total Members</Box>
        <Box>
          <IconButton
            style={{ color: "Black" }}
            onClick={() => {
              setModal(false);
            }}
            size="small"
          >
            {/* <Close /> */}
          </IconButton>
        </Box>
      </Box>
    );
  };

  const ModalHeaderDatePicker = () => {
    return (
      <Box
        padding="0px 14px"
        display="flex"
        style={{
          zIndex: 1,
          color: "black",
          paddingTop: "10px",
          fontWeight: "600",
        }}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box></Box>
        <Box>
          <IconButton
            style={{ color: "Black" }}
            onClick={() => {
              setDatePickerModal(false);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  };

  if (search) {
    return <SearchBooking 
    location={location}
    selectedBrikValue={selectedBrikValue}
    searchBriks={searchBriks}  />;
  }

  return (
    <>
      {console.log("Booking Component render")}
      <Modal
        width="282px"
        height="385px"
        headerComponent={ModalHeaderDatePicker}
        status={datePickerModal}
        saveBtnText="ok"
        saveHandler={() => {
          setDatePickerModal(false);
          setModal(true);
        }}
        borderRadius="none"
      >
        <Box className="">
          <DatePicker
            calendarClassName="custom-calendar"
            selected={startDate}
            minDate={new Date()}
            onChange={(dates) => {
              const [start, end] = dates;
              setSelectedValue([start, end]);
              setStartDate(start);
              setEndDate(end);
              dispatch(
                bookingCheckout({
                  key: "dates",
                  data: [start, end],
                })
              );
            }}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
          />
        </Box>
      </Modal>
      <Modal
        width="470px"
        height={isMobileDevice || isExtraSmallMobileDevice ? "40%" : "200px"}
        headerComponent={ModalHeader}
        status={modal}
        saveBtnText="Confirm"
        saveHandler={() => {
          // console.log('clicked confirm');
          dispatch(
            bookingCheckout({
              key: "members",
              data: {
                adults: adults,
                kids: childrens,
                infants: infants,
                pets: pets,
              },
            })
          );
          // dispatch(
          //   bookingCheckout({
          //     key: "members",
          //     data: {
          //       adults: adults,
          //       kids: childrens,
          //     },
          //   })
          // );

          dispatch(
            bookingCheckout({
              key: "dates",
              data: selectedValue,
            })
          );

          let recent_searches =
            sessionStorage.getItem("recent_searches") == null
              ? []
              : JSON.parse(
                sessionStorage.getItem("recent_searches")
              );

          sessionStorage.setItem(
            "recent_searches",
            JSON.stringify([
              ...recent_searches,
              {
                brik: {
                  property_name: location,
                },
                dates,
                members,
              },
            ])
          );

          let filters = {
            date_check_in: moment(selectedValue[0]).format(
              "YYYYMMDD"
            ),
            date_check_out: moment(selectedValue[1]).format(
              "YYYYMMDD"
            ),
          };

          filters.is_self = value == "my_properties" ? false : false;
          setFilter({
            ...filters,
            q: "all"
          })
          //filters.q = "taj";
          if (selectedBrikValue.trim() !== "") {
            filters.q = selectedBrikValue;
          } else {
            filters.q = "All";
          }
          //filters.q = selectedBrikValue;
          //console.log("HERE2 ",filters.q);
          dispatch(getBriks(filters));
          setSearch(true);
          setModal(false);
        }}
        closeBtnText="Cancel"
        closeHandler={() => {
          setModal(false);
        }}
        borderRadius="none"
      >
        <Grid container spacing={2}>
          <Grid item md="6" xs="12">
            <Box style={{ display: "flex" }}>
              <Box paddingRight="17px" flex={1} fontWeight="600">
                Adults
              </Box>
              <Box
                onClick={() => {
                  let counter = adults - 1;
                  if (counter > 0) setAdults(counter);
                }}
              >
                <img src={LessIcon} />
              </Box>
              <Box padding="0 22px">{adults}</Box>
              <Box
                onClick={() => {
                  let counter = adults + 1;
                  setAdults(counter);
                }}
              >
                <img src={Add} />
              </Box>
            </Box>
          </Grid>
          <Grid item md="6" xs="12">
            <Box style={{ display: "flex" }}>
              <Box paddingRight="8px" flex={1}>
                <Box fontWeight="600">Kids</Box>
                <Box fontSize="10px">Below 18 age group</Box>
              </Box>
              <Box
                onClick={() => {
                  let counter = childrens - 1;
                  if (counter >= 0) setChildrens(counter);
                }}
              >
                <img src={LessIcon} />
              </Box>
              <Box padding="0 22px">{childrens}</Box>
              <Box
                onClick={() => {
                  let counter = childrens + 1;
                  setChildrens(counter);
                }}
              >
                <img src={Add} />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md="6" xs="12">
            <Box style={{ display: "flex" }}>
              <Box paddingRight="17px" fontWeight="600" flex={1}>
                Infants
              </Box>
              <Box
                onClick={() => {
                  let counter = infants - 1;
                  if (counter > 0) setInfants(counter);
                }}
              >
                <img src={LessIcon} />
              </Box>
              <Box padding="0 22px">{infants}</Box>
              <Box
                onClick={() => {
                  let counter = infants + 1;
                  setInfants(counter);
                }}
              >
                <img src={Add} />
              </Box>
            </Box>
          </Grid>
          <Grid item md="6" xs="12">
            <Box style={{ display: "flex" }}>
              <Box paddingRight="8px" flex={1}>
                <Box fontWeight="600">Pets</Box>
                {/* <Box fontSize="10px">Below 12 age group</Box> */}
              </Box>
              <Box
                onClick={() => {
                  let counter = pets - 1;
                  if (counter >= 0) setPets(counter);
                }}
              >
                <img src={LessIcon} />
              </Box>
              <Box padding="0 22px">{pets}</Box>
              <Box
                onClick={() => {
                  let counter = pets + 1;
                  setPets(counter);
                }}
              >
                <img src={Add} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Modal>
      {/* ,backgroundColor:"#F0F0F0" */}
      {(isTabletDevice || isLaptop || isDesktop) && (
        <Box
          style={{ backgroundColor: "#F0F0F0" }}
          className={clsx({
            "web-layout": true,
            // [responsive.web]: true
          })}
        >
          <Box style={{ height: "83vh" }}>
            <Box style={{ padding: "15px 40px" }}>
              <Card
                style={{
                  padding: "14px 35px",
                  marginBottom: "17px",
                  borderRadius: "10px",
                }}
              >
                <RadioGroup
                  aria-label="radio"
                  name="radio1"
                  value={value}
                  onChange={handleChange}
                  size="small"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="my_properties"
                    control={<Radio />}
                    label="My Properties"
                  />
                  <FormControlLabel
                    value="brikitt_community"
                    control={<Radio />}
                    label="Brikitt Community"
                  />
                </RadioGroup>

                <Box
                  style={{
                    color: "#565656",
                    fontSize: "13px",
                    padding: "6px 0",
                  }}
                >
                  Where do you want to enjoy your vacations?
                </Box>
                <CustomSearchComponent
                searchBriks={searchBriks}
                selectedBrik={selectedBrik}
                setSelectedBrik={setSelectedBrik}
                selectedValue={selectedValue}
                setFilter={setFilter}
                setLocation={setLocation}
                setDatePickerModal={setDatePickerModal}
                value={value}
                searchIcon={searchIcon}
                handleSelectedBrik={handleSelectedBrik}
                
                
              />
                
                < Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px 0 3px 0",
                  }}
                >
                  <Box>
                    <Box
                      display="flex"
                      paddingBottom="3px"
                      onClick={handleClickOpen}
                      style={{ cursor: "pointer" }}
                    >
                      <Box style={{ color: "#8B7862" }}>
                        {selectedValue[0] == null
                          ? "Check in"
                          : moment(selectedValue[0]).format("DD MMM")}{" "}
                        -
                      </Box>
                      <Box style={{ color: "#8B7862" }}>
                        {selectedValue[1] == null
                          ? "Check out"
                          : moment(selectedValue[1]).format("DD MMM")}{" "}
                      </Box>
                    </Box>
                    <Box style={{ color: "#565656", fontSize: "12px" }}>
                      {moment(selectedValue[1]).diff(
                        moment(selectedValue[0]),
                        "days"
                      )}{" "}
                      Nights
                    </Box>

                    <SimpleDialog
                      onChange={(newValue) => {
                        setSelectedValue(newValue);
                        console.log("DATES: ", newValue);
                        dispatch(
                          bookingCheckout({
                            key: "dates",
                            data: newValue,
                          })
                        );
                      }}
                      selectedValue={selectedValue}
                      open={open}
                      onClose={handleClose}
                    />
                  </Box>
                  <Box>
                    <Box
                      style={{
                        color: "#8B7862",
                        paddingBottom: "3px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setModal(true);
                      }}
                    >
                      Total Members
                    </Box>
                    <Box
                      display="flex"
                      style={{ color: "#565656", fontSize: "12px" }}
                    >
                      <Box>{adults} Adults</Box>
                      <Box padding="0 6px">|</Box>
                      <Box>{childrens} Kids</Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  display="flex"
                  width="100%"
                  justifyContent="center"
                  position="absolute"
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      // if (Object.keys(brik).length > 0) {
                      dispatch(
                        bookingCheckout({
                          key: "members",
                          data: {
                            adults: adults,
                            kids: childrens,
                            infants: infants,
                            pets: pets,
                          },
                        })
                      );

                      dispatch(
                        bookingCheckout({
                          key: "dates",
                          data: selectedValue,
                        })
                      );

                      let recent_searches =
                        sessionStorage.getItem("recent_searches") == null
                          ? []
                          : JSON.parse(
                            sessionStorage.getItem("recent_searches")
                          );

                      sessionStorage.setItem(
                        "recent_searches",
                        JSON.stringify([
                          ...recent_searches,
                          {
                            brik: {
                              property_name: location,
                            },
                            dates,
                            members,
                          },
                        ])
                      );

                      let filters = {
                        date_check_in: moment(selectedValue[0]).format(
                          "YYYYMMDD"
                        ),
                        date_check_out: moment(selectedValue[1]).format(
                          "YYYYMMDD"
                        ),
                      };

                      filters.is_self = value == "my_properties" ? true : false;
                
                      setFilter({
                        ...filters,
                      })
                      
                      filters.q = "All";
                      //filters.q = location;

                      dispatch(getBriks(filters));
                      setSearch(true);
                      // }
                    }}
                  >
                    Search
                  </Button>
                </Box>
              </Card>
              {sessionStorage.getItem("recent_searches") !== null && (
                <Box className="recent-search">
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box padding="15px 0" fontSize="20px" fontWeight="600">
                      Recent Search
                    </Box>
                    <Box display="flex">
                      {/* <Box padding="0 30px">
                <IconButton>
                  <img src={LeftIcon} />
                </IconButton>
              </Box>
              <Box>
                <IconButton>
                  <img src={RightIcon} />
                </IconButton>
              </Box> */}
                    </Box>
                  </Box>

                  {/* owl-stage-outer  style={{ width: "990px" }} */}
                  <Carousel
                    responsive={{
                      0: {
                        items: 1,
                      },
                      450: {
                        items: 2,
                      },
                      600: {
                        items: 4,
                      },
                      1000: {
                        items: 4,
                      },
                    }}
                    data={
                      sessionStorage.getItem("recent_searches") == null
                        ? []
                        : JSON.parse(sessionStorage.getItem("recent_searches"))
                    }
                    swipeable={true}
                    dots={false}
                    navText={[
                      `<img src=${LeftIcon} />`,
                      ` <img src=${RightIcon} />`,
                    ]}
                    customButtonGroup={false}
                    items={5}
                    loop={false}
                    nav={true}
                  >
                    {(item) => {
                      return (
                        <Card
                          onClick={() => {
                            dispatch(
                              bookingCheckout({
                                key: "all",
                                data: item,
                              })
                            );

                            let filters = {
                              date_check_in: moment(selectedValue[0]).format(
                                "YYYYMMDD"
                              ),
                              date_check_out: moment(selectedValue[1]).format(
                                "YYYYMMDD"
                              ),
                            };

                            filters.is_self =
                              value == "my_properties" ? true : false;
                            setFilter({
                              ...filters,
                              q: "all"
                            })
                            filters.q = item.brik.property_name;
                            setLocation(item.brik.property_name);
                            dispatch(getBriks(filters));

                            setSearch(true);
                          }}
                          style={{
                            cursor: "pointer",
                            padding: "15px 10px",
                            margin: "20px",
                            lineHeight: "1.7",
                            borderRadius: "10px",
                            border: "1px solid #8B7862",
                            borderBottom: "13px solid #8B7862",
                            // height:"150px",
                          }}
                        >
                          <Box
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Box>
                              <img
                                src={LocationIcon}
                                style={{ height: "12px" }}
                              />
                            </Box>
                            <Box
                              color="#B88952"
                              paddingLeft="5px"
                              fontWeight="600"
                            >
                              {item.brik?.property_name}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            style={{ color: "#565656", fontSize: "13px" }}
                          >
                            <Box>{item.members?.adults} Adults</Box>
                            <Box padding="0 8px ">|</Box>
                            <Box>{item.members?.kids} Kids</Box>
                          </Box>
                          <Box
                            display="flex"
                            style={{ color: "#565656", fontSize: "13px" }}
                          >
                            <Box>{moment(item.dates[0]).format("DD MMM")}</Box>
                            <Box padding="0 8px">-</Box>
                            <Box>{moment(item.dates[1]).format("DD MMM")}</Box>
                          </Box>
                        </Card>
                      );
                    }}
                  </Carousel>
                </Box>
              )}

              <Box>
                <Box padding="15px 0" fontSize="20px" fontWeight="600">
                  My Bookings
                </Box>
                <Box style={{ paddingBottom: "12px" }}>
                  <React.Suspense fallback="card loading...">
                    <MyBookingCard bookings={get_bookingsMemonize} />
                  </React.Suspense>
                </Box>
              </Box>
            </Box>
            <br />
            <br />
            <br />
            <br />
          </Box >
        </Box >
      )
      }
      {
        (isMobileDevice || isExtraSmallMobileDevice) && (
          <Box className={responsive.app} backgroundColor="#C4C4C4" margin="-3%">
            <Box>
              <img
                style={{
                  position: "absolute",
                  //top: "-18%",
                  //left: "-4%",
                  zIndex: "-1",
                  width: "100%",
                }}
                src={Bookingbackground}
              />
              <Box
                style={{
                  padding: "15px 40px",
                  zIndex: "1",
                  position: "relative",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    padding: "3% 0",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <IconButton size="small">
                      <ArrowBackIosIcon
                        onClick={() => {
                          history.goBack();
                        }}
                      />
                    </IconButton>
                  </Box>
                  <Box fontSize="28px" fontWeight="700">
                    Book a vacation
                  </Box>
                </Box>
                <Card
                  style={{
                    padding: "14px 35px",
                    marginBottom: "17px",
                    borderRadius: "10px",
                  }}
                >
                  <RadioGroup
                  aria-label="radio"
                  name="radio1"
                  value={value}
                  onChange={handleChange}
                  size="small"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="my_properties"
                    control={<Radio />}
                    label="My Properties"
                  />
                  <FormControlLabel
                    value="brikitt_community"
                    control={<Radio />}
                    label="Brikitt Community"
                  />
                </RadioGroup>

                  {/* <ButtonGroup
              aria-label="outlined button group"
              name="button1"
              value={value}
              onChange={handleChange}
              size="small"
              style={{ color:"#878C89",display: "flex", flexDirection: "row", border:"none",borderRadius:"5px", backgroundColor:"#C0C6C2" }}
            >
              <Button id="select" value="female">My Properties</Button>
              <Button id="select" value="male" className="active">Brikitt Community</Button>
            </ButtonGroup> */}

                  <Box
                    style={{
                      color: "#565656",
                      fontSize: "13px",
                      padding: "5px 0",
                    }}
                  >
                    Where do you want to enjoy your vacation?
                  </Box>

                  <ResponsiveCustomSearchComponent
  searchBriks={searchBriks}
  selectedBrik={selectedBrik}
  setSelectedBrik={setSelectedBrik}
  selectedValue={selectedValue}
  setFilter={setFilter}
  setLocation={setLocation}
  setDatePickerModal={setDatePickerModal}
  value={value}
  searchIcon={searchIcon}
  handleSelectedBrik={handleSelectedBrik}
/>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px 0 3px 0",
                    }}
                  >
                    <Box>
                      <Box
                        display="flex"
                        paddingBottom="3px"
                        onClick={handleClickOpen}
                        style={{ cursor: "pointer" }}
                      >
                        <Box style={{ color: "#8B7862" }}>
                          {selectedValue[0] == null
                            ? "Check in"
                            : moment(selectedValue[0]).format("DD MMM")}{" "}
                          -
                        </Box>
                        <Box style={{ color: "#8B7862" }}>
                          {selectedValue[1] == null
                            ? "Check out"
                            : moment(selectedValue[1]).format("DD MMM")}{" "}
                        </Box>
                      </Box>
                      <Box style={{ color: "#565656", fontSize: "12px" }}>
                        {moment(selectedValue[1]).diff(
                          moment(selectedValue[0]),
                          "days"
                        )}{" "}
                        Nights
                      </Box>
                      <SimpleDialog
                        onChange={(newValue) => {
                          setSelectedValue(newValue);
                          console.log("DATES: ", newValue);
                          dispatch(
                            bookingCheckout({
                              key: "dates",
                              data: newValue,
                            })
                          );
                        }}
                        selectedValue={selectedValue}
                        open={open}
                        onClose={handleClose}
                      />
                    </Box>
                    <Box>
                      <Box
                        style={{
                          color: "#8B7862",
                          paddingBottom: "3px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setModal(true);
                        }}
                      >
                        {parseInt(adults) + parseInt(childrens)} Members
                      </Box>
                      <Box
                        display="flex"
                        style={{ color: "#565656", fontSize: "12px" }}
                      >
                        <Box>{adults} Adults</Box>
                        <Box padding="0 6px">|</Box>
                        <Box>{childrens} Kids</Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                  display="flex"
                  width="100%"
                  justifyContent="center"
                  position="absolute"
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      // if (Object.keys(brik).length > 0) {
                      dispatch(
                        bookingCheckout({
                          key: "members",
                          data: {
                            adults: adults,
                            kids: childrens,
                            infants: infants,
                            pets: pets,
                          },
                        })
                      );

                      dispatch(
                        bookingCheckout({
                          key: "dates",
                          data: selectedValue,
                        })
                      );

                      let recent_searches =
                        sessionStorage.getItem("recent_searches") == null
                          ? []
                          : JSON.parse(
                            sessionStorage.getItem("recent_searches")
                          );

                      sessionStorage.setItem(
                        "recent_searches",
                        JSON.stringify([
                          ...recent_searches,
                          {
                            brik: {
                              property_name: location,
                            },
                            dates,
                            members,
                          },
                        ])
                      );

                      let filters = {
                        date_check_in: moment(selectedValue[0]).format(
                          "YYYYMMDD"
                        ),
                        date_check_out: moment(selectedValue[1]).format(
                          "YYYYMMDD"
                        ),
                      };

                      filters.is_self = value == "my_properties" ? true : false;
                      setFilter({
                        ...filters,
                        q: "all"
                      })
                      filters.q = "All";
                      console.log("here");
                      dispatch(getBriks(filters));
                      setSearch(true);
                      // }
                    }}
                  >
                    Search
                  </Button>
                </Box>
                  {/* <Box textAlign="center" position="absolute" left="30%">
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ width: "195%" }}
                      onClick={() => {
                        dispatch(
                          bookingCheckout({
                            key: "members",
                            data: {
                              adults: adults,
                              kids: childrens,
                            },
                          })
                        );

                        dispatch(
                          bookingCheckout({
                            key: "dates",
                            data: selectedValue,
                          })
                        );

                        let recent_searches =
                          sessionStorage.getItem("recent_searches") == null
                            ? []
                            : JSON.parse(
                              sessionStorage.getItem("recent_searches")
                            );

                        sessionStorage.setItem(
                          "recent_searches",
                          JSON.stringify([
                            ...recent_searches,
                            {
                              brik: {
                                property_name: location,
                              },
                              dates,
                              members,
                            },
                          ])
                        );

                        let filters = {
                          date_check_in: moment(selectedValue[0]).format(
                            "YYYYMMDD"
                          ),
                          date_check_out: moment(selectedValue[1]).format(
                            "YYYYMMDD"
                          ),
                        };

                        filters.is_self = value == "my_properties" ? true : false;
                        setFilter({
                          ...filters,
                          q: "all"
                        })
                        filters.q = location;
                        console.log("HERE");
                        dispatch(getBriks(filters));
                        console.log("HERE 1");
                        setSearch(true);
                      }}
                    >
                      Search
                    </Button>
                  </Box> */}
                </Card>
                {sessionStorage.getItem("recent_searches") !== null && (
                  <Box
                    className="recent-search"
                    style={{
                      padding: "2%",
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      marginTop: "10%",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box padding="0px" fontSize="20px" fontWeight="700">
                        Recent Search
                      </Box>
                      <Box display="flex">
                        {/* <Box padding="0 30px">
                <IconButton>
                  <img src={LeftIcon} />
                </IconButton>
              </Box>
              <Box>
                <IconButton>
                  <img src={RightIcon} />
                </IconButton>
              </Box> */}
                      </Box>
                    </Box>

                    {/* owl-stage-outer  style={{ width: "990px" }} */}
                    <Carousel
                      responsive={{
                        0: {
                          items: 1,
                        },
                        450: {
                          items: 2,
                        },
                        600: {
                          items: 4,
                        },
                        1000: {
                          items: 4,
                        },
                      }}
                      data={
                        sessionStorage.getItem("recent_searches") == null
                          ? []
                          : JSON.parse(sessionStorage.getItem("recent_searches"))
                      }
                      swipeable={true}
                      dots={false}
                      navText={[
                        `<img src=${LeftIcon} />`,
                        ` <img src=${RightIcon} />`,
                      ]}
                      customButtonGroup={false}
                      items={5}
                      loop={false}
                      nav={true}
                    >
                      {(item) => {
                        return (
                          <Card
                            onClick={() => {
                              dispatch(
                                bookingCheckout({
                                  key: "all",
                                  data: item,
                                })
                              );

                              let filters = {
                                date_check_in: moment(selectedValue[0]).format(
                                  "YYYYMMDD"
                                ),
                                date_check_out: moment(selectedValue[1]).format(
                                  "YYYYMMDD"
                                ),
                              };

                              filters.is_self =
                                value == "my_properties" ? true : false;
                              setFilter({
                                ...filters,
                                q: "all"
                              })
                              filters.q = item.brik.property_name;

                              setLocation(item.brik.property_name);

                              dispatch(getBriks(filters));

                              setSearch(true);
                            }}
                            style={{
                              cursor: "pointer",
                              padding: "15px 10px",
                              margin: "20px",
                              lineHeight: "1.7",
                              borderRadius: "10px",
                              border: "1px solid #8B7862",
                              borderBottom: "13px solid #8B7862",
                              // height:"150px",
                            }}
                          >
                            <Box
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Box>
                                <img
                                  src={LocationIcon}
                                  style={{ height: "12px" }}
                                />
                              </Box>
                              <Box
                                color="#B88952"
                                paddingLeft="5px"
                                fontWeight="600"
                              >
                                {item.brik?.property_name}
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              style={{ color: "#565656", fontSize: "13px" }}
                            >
                              <Box>{item.members?.adults} Adults</Box>
                              <Box padding="0 8px ">|</Box>
                              <Box>{item.members?.kids} Kids</Box>
                            </Box>
                            <Box
                              display="flex"
                              style={{ color: "#565656", fontSize: "13px" }}
                            >
                              <Box>{moment(item.dates[0]).format("DD MMM")}</Box>
                              <Box padding="0 8px">-</Box>
                              <Box>{moment(item.dates[1]).format("DD MMM")}</Box>
                            </Box>
                          </Card>
                        );
                      }}
                    </Carousel>
                  </Box>
                )}
                {/* <Box className="recent-search">
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box padding="15px 0" fontSize="20px" fontWeight="700" marginTop="10%">
                Recent Search
              </Box>
              <Box display="flex">
              </Box>
            </Box>
            <Carousel
              responsive={{
                0: {
                  items: 1,
                },
                450: {
                  items: 2,
                },
                600: {
                  items: 4,
                },
                1000: {
                  items: 4,
                },
              }}
              data={
                sessionStorage.getItem("recent_searches") == null
                  ? []
                  : JSON.parse(sessionStorage.getItem("recent_searches"))
              }
              swipeable={true}
              dots={false}
              navText={[`<img src=${LeftIcon} />`, ` <img src=${RightIcon} />`]}
              customButtonGroup={false}
              items={5}
              loop={false}
              nav={true}
            >
              {(item) => {
                return (
                  <Card
                    style={{
                      padding: "10px 10px",
                      lineHeight: "1.7",
                      borderRadius: "10px",
                      border: "1px solid #8B7862",
                      borderBottom: "13px solid #8B7862",
                    }}
                  >
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Box>
                        <img src={LocationIcon} style={{ height: "12px" }} />
                      </Box>
                      <Box color="#B88952" paddingLeft="5px" fontWeight="600">
                        Manali
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      style={{ color: "#565656", fontSize: "13px" }}
                    >
                      <Box>2 Adults</Box>
                      <Box padding="0 8px ">|</Box>
                      <Box>2 Kids</Box>
                    </Box>
                    <Box
                      display="flex"
                      style={{ color: "#565656", fontSize: "13px" }}
                    >
                      <Box>14 April</Box>
                      <Box padding="0 8px">-</Box>
                      <Box>19 April</Box>
                    </Box>
                  </Card>
                );
              }}
            </Carousel>
          </Box> */}

                <Box>
                  <Box padding="15px 0px" fontSize="20px" fontWeight="700">
                    My Bookings
                  </Box>
                  <Box style={{ paddingBottom: "12px" }}>
                    {/* <MyBookingCard bookings={get_bookingsMemonize} /> */}
                    <Grid container spacing={1}>
                      {get_bookings.bookings.length > 0 ? (
                        get_bookings?.bookings?.map((booking) => (
                          <>
                            <Grid item xs={8}>
                              <Card
                                style={{
                                  height: "100%",
                                  // justifyContent: "space-between",
                                  alignItems: "center",
                                  padding: "15px 20px 15px 20px",
                                  lineHeight: "1.8",
                                  borderRadius: "15px",
                                  paddingBottom: "12px",
                                  borderRight: "1px dashed #C4C4C4",
                                }}
                                onClick={() => {
                                  // history.push(
                                  //   `/booking/${booking.booking_id}/details`
                                  // );
                                  history.push(
                                    `/congratulations/${booking.booking_id}`
                                  );
                                }}
                              >
                                <Box
                                  color="#1A4F5D"
                                  fontSize="15px"
                                  fontWeight="600"
                                  style={{ cursor: "pointer" }}
                                >
                                  {booking.brik_name}
                                </Box>
                                <Box color="#9A9A9A">
                                  {booking.booking_user_type == "owner"
                                    ? "Own Brik"
                                    : "Rental Booking"}
                                </Box>
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box style={{ color: "#929292" }}>
                                    <img
                                      src={LocationIcon}
                                      style={{ height: "12px", margin: "auto" }}
                                    />
                                  </Box>
                                  &nbsp;
                                  <Box
                                    fontSize="12px"
                                    margin="auto"
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {booking.location}
                                  </Box>
                                </Box>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <Box>
                                    <Box fontSize="12px" color="#9A9A9A">
                                      Check-in Date
                                    </Box>
                                    <Box color="#1A4F5D" fontWeight="600">
                                      {moment(booking.date_check_in).format(
                                        "DD MMM YYYY"
                                      )}
                                    </Box>
                                  </Box>
                                  <Box>
                                    <Box fontSize="12px" color="#9A9A9A">
                                      Check-out Date
                                    </Box>
                                    <Box color="#1A4F5D" fontWeight="600">
                                      {moment(booking.date_check_out).format(
                                        "DD MMM YYYY"
                                      )}
                                    </Box>
                                  </Box>
                                </Box>
                              </Card>
                            </Grid>

                            <Grid item xs={4}>
                              <Card
                                style={{
                                  //display:"flex",
                                  alignItems: "center",
                                  height: "100%",
                                  padding: "7px 15px",
                                  lineHeight: "1.8",
                                  borderRadius: "15px",
                                  borderLeft: "1px dashed #C4C4C4",
                                }}
                              >
                                <Box>
                                  <Box
                                    style={{
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Box
                                      color="#1A4F5D"
                                      fontSize="27px"
                                      fontWeight="700"
                                      textAlign="center"
                                    >
                                      {moment(booking.date_check_out).diff(
                                        moment(booking.date_check_in),
                                        "days"
                                      )}
                                    </Box>
                                    <Box
                                      color="#1A4F5D"
                                      fontWeight="700"
                                      fontSize="20px"
                                      textAlign="center"
                                    >
                                      Days
                                    </Box>
                                  </Box>
                                  {booking.is_complimentary === false ? (
                                    <div>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Box>
                                      <img
                                        src={BriksCoin}
                                        style={{ height: "23px" }}
                                      />
                                    </Box>
                                    
                                    <Box
                                      color="#8B7862"
                                      fontSize="16px"
                                      style={{
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      &nbsp;&nbsp;
                                      {booking.per_night_coin
                                        ? booking.no_coins_used
                                        : 0}
                                    </Box>
                                  </Box>
                                  <Box
                                    color="#8B7862"
                                    fontSize="12px"
                                    style={{
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    Brikitt Coins &nbsp; + ₹ {booking.amount_paid}
                                  </Box>
                                  </div>
                               ): (
                                <Box style={{ marginLeft: "2px", marginTop: "2%", fontSize: "12px" }}>                      
                                Complimentary Stay
                              </Box>
                               )}
                                </Box>
                              </Card>
                            </Grid>
                          </>
                        ))
                      ) : (
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                          <img src={BookingEmptyState} />
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )
      }
    </>
  );
}

export default Booking;
