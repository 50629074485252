import axios from "axios";
import { result } from "lodash";
import http from "../../http";

export const fetchUserData = async ({ id }) => {
  return http("GET", "posts", { isAuthenticated: false });
};

export const getAuthToken = async (data) => {
  return http("POST", "login/", data, { isAuthenticated: false })
    .then((result) => {
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getCartDetails = async (filters) => {
  var queryString = Object.keys(filters)
    .map(function (key) {
      return key + "=" + filters[key];
    })
    .join("&");

  return http(
    "GET",
    `booking/get_cart_details/?${queryString}`,
    {},
    {
      isAuthenticated: true,
    }
  )
    .then((result) => {
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getOwnerShipData = async (data) => {
  return http("GET", "brik/brik_list/", data, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getPropertyDetails = async (id) => {
  return http("GET", `brik/brik_detail/${id}/`, {}, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getPropertyDetailstype2 = async (id, spv_id) => {
  let query = ""
  if (spv_id) query = `brik_id=${id}&spv_id=${spv_id}`
  else query = `brik_id=${id}`
  console.log('detail query:', query)
  return http(
    "GET",
    `brik/brik_detail/${id}/?${query}`,
    {},
    { isAuthenticated: true }
  );
};

export const addReview = async (apiBody) => {
  return http("POST", `booking/add_review/`, apiBody, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const addBooking = async (apiBody) => {
  return http("POST", `booking/add_booking/`, apiBody, {
    isAuthenticated: true,
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getReviews = async (id) => {
  return http(
    "GET",
    `booking/get_reviews/?brik_id=${id}`,
    {},
    { isAuthenticated: true }
  )
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getBookings = async () => {
  return http("GET", `booking/get_bookings`, {}, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

// export const getBookings = async() => {
//   return http("GET",`booking/get_bookings`,{},{isAuthenticated: true})
//   .then((result)=>{
//     return Promise.resolve(result);
//   })
//   .catch((er)=>{
//     return Promise.reject(er);
//   });
// };

export const getPropertyCowners = async (id) => {
  return http("GET", `brik/brik_owners/${id}/`, {}, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getPaymentReceipt = async (id) => {
  return http(
    "GET",
    `brik/payment_receipt?brik_id=${id}`,
    {},
    { isAuthenticated: true }
  )
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getSPVInfoData = async (id) => {
  return http(
    "GET",
    `brik/spv_info/?brik_id=${id}`,
    {},
    { isAuthenticated: true }
  )
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getSpvTransactionPlan = async (id) => {
  return http(
    "GET",
    `brik/get_virtual_account/?payment_plan_id=${id}`,
    {},
    { isAuthenticated: true }
  )
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const registerToken = async (apiBody) => {
  return http("POST", `fcm/devices/`, apiBody, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const readToken = async (registration_id) => {
  return http(
    "GET",
    `fcm/devices/${registration_id}`,
    {},
    { isAuthenticated: true }
  )
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getDocumentList = async (id) => {
  return http(
    "GET",
    `brik/brik_documents_list/?brik_id=${id}`,
    {},
    { isAuthenticated: true }
  )
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getPaymentDetails = async (payment_plan_id, installment_id, booking_balance_amount, user_id, brik_owner_id, maintenance_id, annual_maintenance_amount, facility_management_id, facility_management_amount) => {
  let query = ""
  if (payment_plan_id || installment_id) query = `payment_plan_id=${payment_plan_id}&installment_id=${installment_id}`
  if (brik_owner_id && user_id && booking_balance_amount) query = `payment_type=booking_balance_amount_payment&booking_balance_amount=${booking_balance_amount}&user_id=${user_id}&brik_owner_id=${brik_owner_id}`
  if (maintenance_id && annual_maintenance_amount) query = `payment_type=annual_maintenance_payment&annual_maintenance_amount=${annual_maintenance_amount}&maintenance_id=${maintenance_id}`
  if (facility_management_id && facility_management_amount) query = `payment_type=facility_management_payment&facility_management_amount=${facility_management_amount}&facility_management_id=${facility_management_id}`
  console.log("query created here is-", query)
  return http(
    "GET",
    `brik/create_payment_order/?${query}`,
    {},
    { isAuthenticated: true }
  )
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getTransactionData = async (id) => {
  return http(
    "GET",
    `brik/new_maintenance/?brik_id=${id}`,
    {},
    { isAuthenticated: true }
  )
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const forgotPassword = async (apiBody) => {
  return http("POST", `request-reset-email`, apiBody, {
    isAuthenticated: false,
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const changePassword = async (apiBody) => {
  try {
    const result = await http("POST", `change_password/`, apiBody, {
      isAuthenticated: true,
    });
    
    return Promise.resolve(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const resetPassword = async (apiBody) => {
  return http("PATCH", `password-reset-complete/`, apiBody, {
    isAuthenticated: false,
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};


export const updatePersonalDetails = async ({ user_id, apiBody, headers }) => {
  return http("PUT", `personal_info/${user_id}/`, apiBody, {
    isAuthenticated: true,
    headers,
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const addPersonalDetails = async ({ apiBody, headers }) => {
  return http("POST", `personal_info/`, apiBody, {
    isAuthenticated: true,
    headers,
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const updateContactDetails = async ({ user_id, apiBody }) => {
  return http("PUT", `contact_info/${user_id}/`, apiBody, {
    isAuthenticated: true,
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const addContactDetails = async ({ apiBody }) => {
  return http("POST", `contact_info/`, apiBody, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const updateIdCard = async ({ card_id, apiBody, headers }) => {
  return http("PUT", `id_cards/${card_id}/`, apiBody, {
    isAuthenticated: true,
    headers,
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const addIdCard = async ({ apiBody, headers }) => {
  return http("POST", `id_cards/`, apiBody, { isAuthenticated: true, headers })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const addBankDetails = async ({ apiBody, headers }) => {
  return http("POST", `bank_info/`, apiBody, { isAuthenticated: true, headers })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const updateBankDetails = async ({ user_id, apiBody, headers }) => {
  return http("PUT", `bank_info/${user_id}/`, apiBody, {
    isAuthenticated: true,
    headers,
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const updateNomineeDetails = async ({ user_id, apiBody, headers }) => {
  return http("PUT", `nominee_info/${user_id}/`, apiBody, {
    isAuthenticated: true,
    headers,
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const addNomineeDetails = async ({ apiBody, headers }) => {
  return http("POST", `nominee_info/`, apiBody, {
    isAuthenticated: true,
    headers,
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const readNotifications = async (apiBody) => {
  return http("GET", `brik/notification_read/`, {}, { isAuthenticated: true })
    .then((result) => {
      console.log(Promise.resolve(result.data));
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const updateDocumentData = async (apiBody) => {
  return http("POST", `/terms_conditions/`, apiBody, {
    isAuthenticated: true,
    headers: {
      "content-type":
        "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
    },
  })
    .then((result) => {
      console.log(Promise.resolve(result.data));
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const searchBooking = async (filters) => {
  var queryString = Object.keys(filters)
    .map(function (key) {
      return key + "=" + filters[key];
    })
    .join("&");

  console.log("SEARCH BOOKING: ", queryString);

  return http(
    "GET",
    `/booking/search_briks/?${queryString}`,
    {},
    { isAuthenticated: true }
  )
    .then((result) => {
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getDocumentById = async (id) => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_AADHAR_API_URL}/v2/client/document/${id}`,
    headers: {
      Authorization: `Basic ${process.env.REACT_APP_AADHARESIGN_TOKEN}`,
      // 'Authorization':'Basic '+window.btoa('AIXBIB9I5NKCUS6HV5AGQQ8FWCH9IOHY:CHPB16MQ3AX79J2SUHXS23X7RHS5REI')
    },
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.resolve(er);
    });
};

export const fetchBookings = async (apiBody) => {
  return http("GET", `booking/get_bookings/`, {}, { isAuthenticated: true })
    .then((result) => {
      console.log(Promise.resolve(result.data));
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getNotificationsList = async (apiBody) => {
  return http("GET", `brik/notification_list/`, {}, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });

  // return [
  //     {
  //         id:"21",
  //         read:false,
  //         brik_name:"Rio De Goa",
  //         title:"This is title",
  //         body:"hello this is test notificaiton",
  //         notificaiton_date:"2021-12-21T09:37:14.886009Z"
  //     },
  //     {
  //         id:"21",
  //         read:false,
  //         brik_name:"Rio De Goa",
  //         title:"This is title",
  //         body:"hello this is test notificaiton",
  //         notificaiton_date:"2021-12-21T09:37:14.886009Z"
  //     },
  //     {
  //         id:"21",
  //         read:true,
  //         brik_name:"Rio De Goa",
  //         title:"This is title",
  //         body:"hello this is test notificaiton",
  //         notificaiton_date:"2021-12-21T09:37:14.886009Z"
  //     }
  // ]
};

export const getProfileData = async (user_id) => {
  // return {
  //     personal_details: {
  //         name: "Mukul",
  //         lastname: "jain"
  //     },
  //     contact_info: {

  //     },
  //     id_cards: {

  //     },
  //     back_account_details: {

  //     },
  //     nominee_details: {

  //     }
  // }
  return http("GET", `user_profile/${user_id}/`, {}, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getBrikSchedule = async (brik_id, spv_id, value) => {
  let url = "";
  if (value === "1") {
    url = `booking/get_bookings?brik_id=${brik_id}&spv_id=${spv_id}`;
  }
  if (value === "2") {
    url = `booking/get_bookings?brik_id=${brik_id}&spv_id=${spv_id}&is_upcoming=${false}`;
  }
  if (value === "3") {
    url = `booking/get_bookings?brik_id=${brik_id}&spv_id=${spv_id}&is_upcoming=${true}`;
  }

  return http("GET", url, {}, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getBrikScheduleCalendar = async (brik_id, month, year) => {
  return http(
    "GET",
    `/booking/get_booking_calendar/?brik_id=${brik_id}&month=${month}&year=${year}`,
    {},
    { isAuthenticated: true }
  )
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getBookingByID = async (booking_id) => {
  return http(
    "GET",
    `/booking/get_bookings/?booking_id=${booking_id}`,
    {},
    { isAuthenticated: true }
  )
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getWalletBalanceApi = async (booking_id) => {
  return http("GET", `/wallet/get_balance`, {}, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getBookingCalendarApi = async ({ brik_id, spv_id, month, year }) => {
  return http(
    "GET",
    `/booking/get_booking_calendar/?brik_id=${brik_id}&spv_id=${spv_id }&month=${month}&year=${year}`,
    {},
    { isAuthenticated: true }
  )
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getGrowthData = async (brik_id, spv_id) => {
  return http(
    "GET",
    `brik/get_growth_data/?brik_id=${brik_id}&spv_id=${spv_id}`,
    {},
    { isAuthenticated: true }
  )
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const postContactDetailRequest = async (apiBody) => {
  return http("POST", `brik/post_contact_detail_request/`, apiBody, {
    isAuthenticated: true,
  })
    .then((result) => {
      console.log(Promise.resolve(result.data));
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};
export const cancelBookingRequest = async(body, booking_id)=>
{   
    const authToken = localStorage.getItem('token');
    console.log(booking_id, authToken, "*******!!!!!")
    return http("DELETE",`booking/cancel_booking/${booking_id}/`, body, {
        isAuthenticated:true,
        
    })
    .then((res)=>Promise.resolve(res.data))
    .catch((err)=>Promise.reject(err))
}