import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';

const CustomSearchComponent = ({ searchBriks, selectedBrik, setSelectedBrik, selectedValue, setFilter, setLocation, setDatePickerModal, value, searchIcon,handleSelectedBrik }) => {
  const [searchValue, setSearchValue] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  
  console.log("incustomsearchcomponent searchbriks",searchBriks);
  const filteredBriks = searchBriks.filter(brik =>
    brik?.property_name.toLowerCase().includes(searchValue.toLowerCase())
  );
 
  const handleSearch = () => {
    let filters = {
      date_check_in: moment(selectedValue[0]).format("YYYYMMDD"),
      date_check_out: moment(selectedValue[1]).format("YYYYMMDD"),
      is_self: value === "my_properties" ? true : false,
      q: searchValue || "all"
    };
    setFilter(filters);
    setLocation(searchValue);
    setDatePickerModal(true);
  };
  console.log(filteredBriks);

  return (
    <div style={{ position: 'relative' }}>
      <FormControl style={{ width: '100%', paddingTop: 20 }}>
        <InputLabel htmlFor="briks"></InputLabel>
        <TextField
          id="briks"
          variant="outlined"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                style={{ cursor: 'pointer' }} // Set cursor style to pointer
              >
                <SearchIcon />
              </InputAdornment>
            )
          }}
          placeholder="Search for a brik"
        />
        {isDropdownOpen && (
          <Select
            open={isDropdownOpen}
            onClose={() => setIsDropdownOpen(false)}
            value={selectedBrik}
            onChange={(event) => {
              setSelectedBrik(event.target.value);
              handleSearch();
              handleSelectedBrik(event.target.value);
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top", // Change to bottom to move the dropdown up
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
            style={{ position: 'absolute', width: '100%', top: 'calc(100% - 55px)', left: 0 }} // Adjust top position
          >
            {searchValue === '' ?
              searchBriks.map((brik) => (
                <MenuItem key={brik?.property_name} value={brik?.property_name}>{brik?.property_name}</MenuItem>
              ))
              :
              filteredBriks.map((brik) => (
                <MenuItem key={brik?.property_name} value={brik?.property_name}>{brik?.property_name}</MenuItem>
              ))
            }
             
          </Select>
        )}
        <FormHelperText>Select a brik</FormHelperText>
      </FormControl>
    </div>
  );
};




export default CustomSearchComponent;

