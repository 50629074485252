import {
  Avatar,
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  Card,
} from "@material-ui/core";
import React, { useState } from "react";
import queryString from "query-string";
import gold from "../assets/icons/GoldIcon.png";
import wifiIcon from "../assets/icons/wifi.png";
import CallIcon from "../assets/icons/CallIcon.svg";
import { responsiveStyles } from "../assets/css/generalStyling";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import galleryIcon from "../assets/images/gallery.png";
import KingBedOutlinedIcon from "@material-ui/icons/KingBedOutlined";
import BathtubOutlinedIcon from "@material-ui/icons/BathtubOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import WifiOutlinedIcon from "@material-ui/icons/WifiOutlined";
import RoomIcon from "@material-ui/icons/Room";
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Rating from "@material-ui/lab/Rating";
import { BrikCoin } from "../assets/assets";
import RightIcon from "@material-ui/icons/ArrowForwardIos";
import LeftIcon from "@material-ui/icons/ArrowBackIos";
import PropertyReview from "./component/PropertyReview";
import { useParams } from "react-router-dom";
import { Map, GoogleApiWrapper } from "google-maps-react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import {
  getCartDetails,
  getPropertyDetails,
  getPropertyDetailstype2,
  getReviews,
} from "../store/actions/apis";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getBookingDetails,
  setCartDetails,
} from "../store/actions/bookingActions";
import { bookingCheckout as bookingCheckoutAction } from "../store/actions/bookingActions";
import { useSnackbar } from "notistack";

const PropertyReviewRating = React.lazy(() =>
  import("./component/PropertyReviewRating")
);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

function BookingReview() {
  const history = useHistory();
  const dispatch = useDispatch();
  const responsive = responsiveStyles();
  let params = useParams();

  let [reviews, setReviews] = useState({
    rating_dict: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },
    reviews: [],
  });

  const reviewsMemo = React.useMemo(() => {
    return reviews;
  }, [reviews]);

  const brik_id = React.useMemo(() => {
    return params.brik_id;
  }, [params]);

  const notification = useSnackbar();
  const [propertyDetails, setPropertyDetails] = React.useState({
    amenities: [{}],
    property_detail: [{}],
    brik_image: [{}],
    spv_info: [{}],
    bookings: [],
  });

  React.useEffect(() => {
    let qs = queryString.parse(history.location.search);

    dispatch(
      bookingCheckoutAction({
        key: "dates",
        data: [moment(qs.date_check_in), moment(qs.date_check_out)],
      })
    );
  }, [history.location.search]);

  React.useEffect(() => {
    getReviews(brik_id).then((result) => {
      // console.log(
      //   "Booking Id in PropertyReviewRating: ",
      //   propertyDetails.bookings.length > 0
      //     ? propertyDetails.bookings[0].id
      //     : false
      // );
      console.log("Data for Reviews: " + result.data.brik_id);
      setReviews(result.data);

      console.log("Data Dictionary", result.data.rating_dict["1"]);
    });

    getPropertyDetailstype2(brik_id, localStorage.getItem('spv_id')).then((result) => {
      const { data } = result;
      setPropertyDetails(data);
      data.id = data.brik_id;
      dispatch(
        bookingCheckoutAction({
          key: "brik",
          data: data,
        })
      );
    });
  }, [brik_id]);

  const { bookingCheckout } = useSelector((state) => state.booking);

  const imag = {
    height: 80,
    width: "100%",
    borderRadius: "10px",
  };

  return (
    <>
      {console.log("RENDER COMPONENETSSD")}
      <Box className={responsive.web}>
        <Box style={{ margin: "0 30px" }}>
          <Grid container>
            <Grid item xs={12} sm={7} style={{ paddingRight: "20px" }}>
              <Box fontSize="22px" paddingBottom="5px" fontWeight="bold">
                {propertyDetails.property_name}
              </Box>
              <Box style={{ display: "flex" }}>
                <Box>
                  <RoomIcon style={{ color: "red", fontSize: "18px" }} />
                </Box>
                <Box>{propertyDetails.location}</Box>
              </Box>
              <Box>
                <img
                  src={propertyDetails.brik_cover_image}
                  style={{ width: "112%" }}
                />
              </Box>
              <Box fontSize="18px" padding="14px 0px">
                Description
              </Box>
              <Box style={{ width: "112%" }}>{propertyDetails.description}</Box>

              <Box padding="10px 0">
                <Box padding="10px 0" fontSize="18px">
                  Amenities
                </Box>

                <Grid container>
                  {propertyDetails?.amenities?.map((amenitie, key) => {
                    return (
                      <Grid key={key} item xs={6}>
                        <Box display="flex" padding="5px 0">
                          <Box padding="0 10px">
                            {amenitie.aminety_icon != null ? (
                              <img
                                style={{ height: "30px" }}
                                src={amenitie.aminety_icon}
                              />
                            ) : (
                              <WifiOutlinedIcon />
                            )}
                          </Box>
                          <Box>
                            <Typography>{amenitie.amenity_feature}</Typography>
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Grid>
            <Grid item sm={1}></Grid>
            <Grid
              item
              xs={4}
              sm={4}
              // order={{ xs: 4, sm: 4 }}
              style={{ marginTop: "10px" }}
            >
              <Box
                style={{
                  marginTop: "50px",
                  backgroundColor: "#F5F5F5",
                  padding: "12px",
                  borderRadius: "10px",
                }}
              >
                <Box style={{ backgroundColor: "white", borderRadius: "10px" }}>
                  <Box
                    padding="20px"
                    borderBottom="1px solid #EBEBEB"
                    justifyContent="space-between"
                    display="flex"
                  >
                    <Box flex={1}>
                      <KingBedOutlinedIcon />
                    </Box>
                    <Box flex={1}>{propertyDetails.bedroom}</Box>
                    <Box flex={1}>BEDROOMS</Box>
                  </Box>
                  <Box
                    padding="20px"
                    borderBottom="1px solid #EBEBEB"
                    justifyContent="space-between"
                    display="flex"
                  >
                    <Box flex={1}>
                      <BathtubOutlinedIcon />
                    </Box>
                    <Box flex={1}>{propertyDetails.bathroom}</Box>
                    <Box flex={1}>BATHROOMS</Box>
                  </Box>
                  <Box
                    padding="20px"
                    justifyContent="space-between"
                    display="flex"
                  >
                    <Box flex={1}>
                      <HomeOutlinedIcon />
                    </Box>
                    <Box flex={1}>{propertyDetails.property_area}</Box>
                    <Box flex={1}>
                      SF<sup>2</sup>
                    </Box>
                  </Box>
                </Box>

                <Box padding="32px 11px">
                  <Box
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box>
                      <Box
                        style={{
                          padding: "2px 0",
                          color: "#7C7C7C",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        Brik Value
                      </Box>
                      <Box>₹ {propertyDetails.brik_value}</Box>
                    </Box>
                    <Box>
                      <Box
                        style={{
                          padding: "2px 0",
                          color: "#7C7C7C",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        Rent Value
                      </Box>
                      <Box>
                        ₹{" "}
                        {propertyDetails.per_night_inr
                          ? propertyDetails.per_night_inr
                          : 0}
                        /Night
                      </Box>
                    </Box>
                  </Box>
                  <Box style={{ margin: "8px 0" }}>
                    <Button
                      onClick={() => {
                        getCartDetails({
                          date_check_in: moment(
                            bookingCheckout.dates[0]
                          ).format("YYYYMMDD"),
                          date_check_out: moment(
                            bookingCheckout.dates[1]
                          ).format("YYYYMMDD"),
                          brik_id: propertyDetails.brik_id,
                          spv_id: localStorage.getItem('spv_id'),
                          is_self: false,
                          adult: bookingCheckout.members.adults,
                          children: bookingCheckout.members.kids,
                        })
                          .then((result) => {
                            console.log(
                              "RESULT: [getBookingDetails]: ",
                              result[0]
                            );
                            dispatch(setCartDetails(result[0]));
                            history.push("/payment");
                          })
                          .catch((er) => {
                            notification.enqueueSnackbar(
                              "Brik is already booked",
                              {
                                variant: "error",
                              }
                            );
                            console.log("ownership list ERR: ", er);
                          });
                        // dispatch(
                        // getBookingDetails({
                        //   date_check_in: moment(
                        //     bookingCheckout.dates[0]
                        //   ).format("YYYYMMDD"),
                        //   date_check_out: moment(
                        //     bookingCheckout.dates[1]
                        //   ).format("YYYYMMDD"),
                        //   brik_id: propertyDetails.brik_id,
                        //   is_self: false,
                        //   adult: bookingCheckout.members.adults,
                        //   children: bookingCheckout.members.kids,
                        // })
                        // );
                      }}
                      variant="contained"
                      color="secondary"
                      fullWidth
                    >
                      BOOK YOUR VACATION
                    </Button>
                  </Box>
                </Box>

                <Box
                  padding="15px 10px"
                  display="flex"
                  flexDirection="column"
                  style={{
                    backgroundColor: "#2B4041",
                    color: "white",
                    borderRadius: "10px",
                    marginTop: "5%",
                  }}
                >
                  <Box
                    style={{ width: "100%" }}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Box padding="0 10px">
                      <Avatar src={propertyDetails.brik_manager_image} />
                    </Box>
                    <Box>
                      <Box fontWeight="600" padding="2px 0">
                        {propertyDetails.brik_manager}
                      </Box>
                      <Box>Brik Manager</Box>
                    </Box>
                  </Box>
                  <Box width="100%" paddingTop="13px">
                    <Button
                      onClick={() => {
                        window.open(
                          `tel:${propertyDetails.brik_manager_contact}`
                        );
                      }}
                      variant="outlined"
                      fullWidth
                      style={{ backgroundColor: "white" }}
                    >
                      <Box
                        style={{ color: "#4A8EAB" }}
                        display="flex"
                        alignItems="center"
                      >
                        <img src={CallIcon} />
                        <Box style={{ paddingLeft: "10px" }}>Brik Manager</Box>
                      </Box>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Box padding="10px 0" fontSize="18px">
                Gallery
              </Box>
              <Box>
                <Grid container>
                  {propertyDetails?.brik_image?.map((image) => {
                    return (
                      <Grid item xs={2}>
                        <img
                          onClick={() => {
                            // setEnargeView(true);
                            // setEnargeViewImg(image.image);
                          }}
                          style={{ ...imag, height: "200px", padding: "10px" }}
                          src={image.image}
                          alt="image"
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <PropertyReviewRating
            bookingId={
              propertyDetails?.bookings?.length > 0
                ? propertyDetails.bookings[0].id
                : false
            }
            brikId={brik_id}
            reviews={reviewsMemo}
          />
          <PropertyReview reviews={reviewsMemo} brikId={brik_id} />
          <Grid container>
            <Grid item xs={12}>
              <Box padding="50px 0">
                <Box fontSize="18px" padding="15px 0">
                  Owners
                </Box>
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <Box position="relative" display="flex" alignItems="center">
                      <Box position="absolute">
                        <Avatar />
                      </Box>
                      <Box position="absolute" left="26px">
                        <Avatar />
                      </Box>
                      <Box position="absolute" left="52px">
                        <Avatar />
                      </Box>
                      <Box position="absolute" left="80px">
                        <Avatar />
                      </Box>
                      <Box position="absolute" left="107px">
                        <Avatar />
                      </Box>
                    </Box>
                    <Box paddingLeft="180px" color="#2D9CDB">
                      11 Owners
                    </Box>
                    <Box paddingLeft="8px">
                      <RightIcon
                        style={{
                          height: "24px",
                          color: "#2D9CDB",
                          paddingTop: "4px",
                        }}
                      />
                    </Box>
                  </Box>

                  <Box display="flex">
                    <Box paddingRight="20px">
                      <Box display="flex" alignItems="center">
                        <Box padding="4px 5px 0px 0px">
                          <img src={BrikCoin} height="15px" />
                        </Box>
                        <Box color="#8B7862" fontWeight="600">
                          {propertyDetails.per_night_coin
                            ? propertyDetails.per_night_coin
                            : 0}
                          /Night
                        </Box>
                        <Box color="#9B9B9B" paddingLeft="5px" fontWeight="600">
                          ₹{" "}
                          {propertyDetails.per_night_inr
                            ? propertyDetails.per_night_inr
                            : 0}
                          /Night
                        </Box>
                      </Box>
                      <Box color="#9B9B9B">
                        {moment(bookingCheckout.dates[0]).format("DD MMM")} -{" "}
                        {moment(bookingCheckout.dates[1]).format("DD MMM")}
                      </Box>
                    </Box>
                    <Box>
                      <Button
                        onClick={() => {
                          getCartDetails({
                            date_check_in: moment(
                              bookingCheckout.dates[0]
                            ).format("YYYYMMDD"),
                            date_check_out: moment(
                              bookingCheckout.dates[1]
                            ).format("YYYYMMDD"),
                            brik_id: propertyDetails.brik_id,
                            spv_id: localStorage.getItem('spv_id'),
                            is_self: false,
                            adult: bookingCheckout.members.adults,
                            children: bookingCheckout.members.kids,
                          })
                            .then((result) => {
                              console.log(
                                "RESULT: [getBookingDetails]: ",
                                result[0]
                              );
                              dispatch(setCartDetails(result[0]));
                              history.push("/payment");
                            })
                            .catch((er) => {
                              notification.enqueueSnackbar(
                                "Brik is already booked",
                                {
                                  variant: "error",
                                }
                              );
                              console.log("ownership list ERR: ", er);
                            });
                          // dispatch(
                          // getBookingDetails({
                          //   date_check_in: moment(
                          //     bookingCheckout.dates[0]
                          //   ).format("YYYYMMDD"),
                          //   date_check_out: moment(
                          //     bookingCheckout.dates[1]
                          //   ).format("YYYYMMDD"),
                          //   brik_id: propertyDetails.brik_id,
                          //   is_self: false,
                          //   adult: bookingCheckout.members.adults,
                          //   children: bookingCheckout.members.kids,
                          // })
                          // );
                        }}
                        variant="contained"
                        color="secondary"
                      >
                        Book Your Vacation
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={responsive.app}>
        {/* <Box style={{ width: "90%", position: "absolute", top: "20px", display: "flex", alignItems: "center" }}>
        <Box style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
          <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
             <IconButton onClick={() => {
               history.goBack()
             }}>
              <ArrowBackIosIcon style={{ color: "white" }} />
              </IconButton>
            </Box>
          <Box style={{ color: "white" }} fontSize="24px" fontWeight="600">{propertyDetails.property_name}</Box>
          </Box>
          <Box border='1px solid black' borderRadius='10px' padding='0 8px' color='#FF9E12' fontWeight='600' style={{ backgroundColor: '#FFF9E7', maxHeight: "24px" }}>{propertyDetails.property_type}</Box>
        </Box>
    </Box> */}
        <Grid item xs={12}>
          <Card
            style={{
              position: "relative",
              height:"60%",
              cursor: "pointer",
            }}
            // onClick={() => {
            //   console.log("BRIKTITT: ", brik);
            //   dispatch(
            //     bookingCheckout({
            //       key: "brik",
            //       data: brik,
            //     })
            //   );
            //   history.push(`/booking/${brik.id}`);
            // }}
          >
            <Box
              style={{
                boxShadow: "inset 0px 70px 70px #090909",
                height: "100%",
                backgroundImage: `url(${propertyDetails.brik_cover_image})`,
                backgroundSize: "100% 280px",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Box>
                <img
                  src={propertyDetails.brik_cover_image}
                  style={{
                    height: "100%",
                    width: "100%",
                    visibility: "hidden",
                  }}
                />
              </Box>
            </Box>
            <Box position="absolute" top="15px" right="17px">
              {propertyDetails.property_type == "Gold leisure" && (
                <img src={gold} />
              )}
            </Box>
            <Box position="absolute" top="18px" left="10px">
              <Box
                style={{
                  padding: "3%",
                  color: "white",
                }}
              >
                <Box>
                <Box
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <ArrowBackIosIcon style={{color:"white"}} />
                </Box>
                </Box>
                <Box fontSize="24px" fontWeight="700">
                  {propertyDetails.property_name}
                </Box>
                <Box fontSize="14px" fontWeight="400">
                  {propertyDetails.location}
                </Box>
              </Box>
            </Box>
            {propertyDetails.total_review > 0 && (
              <Box
                component="fieldset"
                //mb={3}
                borderColor="transparent"
                position="absolute"
                bottom="5px"
                color="white"
                display="flex"
              >
                <Rating
                  name="read-only"
                  value={propertyDetails.total_review}
                  readOnly
                />
                &nbsp;({propertyDetails.average_review}) reviews
              </Box>
            )}
          </Card>
        </Grid>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "2%",
          }}
        >
          <Box>
            <Box
              style={{
                padding: "2px 0",
                color: "#7C7C7C",
                fontSize: "14px",
                fontWeight: "600",
                letterSpacing:"0.03em"
              }}
            >
              Brik Value
            </Box>
            <Box style={{
              fontWeight:"700",
              fontSize:"18px",
              lineHeight:"14px",
              letterSpacing:"0.03em",
              color:"#212630"
            }}>₹ {propertyDetails.brik_value}</Box>
          </Box>
          <Box>
            <Box
              style={{
                padding: "2px 0",
                color: "#7C7C7C",
                fontSize: "14px",
                fontWeight: "600",
                letterSpacing:"0.03em"
              }}
            >
              Rent Value
            </Box>
            <Box style={{
              fontWeight:"700",
              fontSize:"18px",
              lineHeight:"14px",
              letterSpacing:"0.03em",
              color:"#212630"
            }}>
              ₹{" "}
              {propertyDetails.per_night_inr
                ? propertyDetails.per_night_inr
                : 0}
              /Night
            </Box>
          </Box>
        </Box>
        <hr />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="13px 53px"
        >
          <Box style={{ textAlign: "center" }}>
            <Box display="flex" alignItems="center">
              <KingBedOutlinedIcon />
              <Box style={{ padding: "0px 8px" }}>
                {propertyDetails.bedroom}
              </Box>
            </Box>
            <Typography style={{ fontSize: "12px" }}>BEDROOMS</Typography>
          </Box>

          <Box textAlign="center">
            <Box display="flex" alignItems="center">
              <BathtubOutlinedIcon />
              <Box style={{ padding: "0px 8px" }}>
                {propertyDetails.bathroom}
              </Box>
            </Box>
            <Typography style={{ fontSize: "12px" }}>BATHROOMS</Typography>
          </Box>

          <Box textAlign="center">
            <Box display="flex" alignItems="center">
              <HomeOutlinedIcon />
              <Box style={{ padding: "0px 8px" }}>
                {propertyDetails.property_area}
              </Box>
            </Box>
            <Typography style={{ fontSize: "12px" }}>
              SF<sup>2</sup>
            </Typography>
          </Box>
        </Box>
        <hr />
        <Box fontSize="18px" padding="14px 14px" fontWeight="600">
          Description
        </Box>
        <Box style={{ padding: "2%" }}>{propertyDetails.description}</Box>
        <Box padding="10px 0">
          <Box padding="10px 10px" fontSize="18px" fontWeight="600">
            Amenities
          </Box>

          <Grid container>
            {propertyDetails?.amenities?.map((amenitie, key) => {
              return (
                <Grid key={key} item xs={6}>
                  <Box display="flex" padding="5px 0">
                    <Box padding="0 10px">
                      {amenitie.aminety_icon != null ? (
                        <img
                          style={{ height: "30px" }}
                          src={amenitie.aminety_icon}
                        />
                      ) : (
                        <WifiOutlinedIcon />
                      )}
                    </Box>
                    <Box>
                      <Typography>{amenitie.amenity_feature}</Typography>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <Box padding="10px 10px" fontSize="18px">
              Gallery
            </Box>
            <Box>
              <Grid container>
                {propertyDetails?.brik_image?.map((image) => {
                  return (
                    <Grid item xs={2}>
                      <img
                        onClick={() => {
                          // setEnargeView(true);
                          // setEnargeViewImg(image.image);
                        }}
                        style={{ ...imag, padding: "10px" }}
                        src={image.image}
                        alt="image"
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box padding="2%">
          <Box
            padding="15px 10px"
            display="flex"
            flexDirection="column"
            style={{
              backgroundColor: "#2B4041",
              color: "white",
              borderRadius: "10px",
              marginTop: "5%",
            }}
          >
            <Box
              style={{ width: "100%" }}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Box padding="0 10px">
                <Avatar src={propertyDetails.brik_manager_image} />
              </Box>
              <Box>
                <Box fontWeight="600" padding="2px 0">
                  {propertyDetails.brik_manager}
                </Box>
                <Box>Brik Manager</Box>
              </Box>
            </Box>
            <Box width="100%" paddingTop="13px">
              <Button
                onClick={() => {
                  window.open(`tel:${propertyDetails.brik_manager_contact}`);
                }}
                variant="outlined"
                fullWidth
                style={{ backgroundColor: "white" }}
              >
                <Box
                  style={{ color: "#4A8EAB" }}
                  display="flex"
                  alignItems="center"
                >
                  <img src={CallIcon} />
                  <Box style={{ paddingLeft: "10px" }}>Brik Manager</Box>
                </Box>
              </Button>
            </Box>
          </Box>
        </Box>
        <Box padding="2%">
          <PropertyReviewRating reviews={reviews} brikId={brik_id} />
          <PropertyReview reviews={reviews} brikId={brik_id} padding="2%" />

          <Box paddingBottom="60px">
            <Box fontSize="18px" padding="15px 0" fontWeight="600">
              Owners
            </Box>
            <Box style={{ display: "flex", justifyContent: "space-between", backgroundColor:"white", boxShadow:"0px 4px 20px -10px rgba(0, 0, 0, 0.27)", borderRadius:"5px", padding:"5%"}}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Box position="relative" display="flex" alignItems="center">
                  <Box position="absolute">
                    <Avatar />
                  </Box>
                  <Box position="absolute" left="26px">
                    <Avatar />
                  </Box>
                  <Box position="absolute" left="52px">
                    <Avatar />
                  </Box>
                  <Box position="absolute" left="80px">
                    <Avatar />
                  </Box>
                  <Box position="absolute" left="107px">
                    <Avatar />
                  </Box>
                </Box>
                <Box paddingLeft="180px" color="#2D9CDB">
                  11 Owners
                </Box>
                <Box paddingLeft="8px">
                  <RightIcon
                    style={{
                      height: "24px",
                      color: "#2D9CDB",
                      paddingTop: "4px",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          padding="3%"
          fixed
          display="flex"
          width="100%"
          position="absolute"
          bottom="12%"
          style={{ display: "flex", justifyContent: "space-between", boxShadow:"0px 0px 6px 1px rgba(0, 0, 0, 0.15)",backgroundColor:"white" }}
        >
          <Box alignItems="left">
            <Box display="flex">
              <Box>
                <img
                  src={BrikCoin}
                  style={{ height: "16px", paddingTop: "4px" }}
                />
              </Box>
              <Box color="#8B7862" paddingLeft="3px" fontWeight="600">
                {propertyDetails.per_night_coin
                  ? propertyDetails.per_night_coin
                  : 0}
                /Night
              </Box>
              <Box color="#8B7862" fontWeight="600">
                &nbsp;
                ₹(
                {propertyDetails.per_night_inr
                  ? propertyDetails.per_night_inr
                  : 0}
                /Night)
              </Box>
            </Box>
            <Box color="#8B7862" fontWeight="600">
              {moment(bookingCheckout.dates[0]).format("DD MMM")} -{" "}
              {moment(bookingCheckout.dates[1]).format("DD MMM")}
            </Box>
          </Box>
          <Button
            alignItems="right"
            variant="contained"
            color="#4A8EAB"
            style={{backgroundColor:"#4A8EAB", width:"40%", color:"white"}}
            onClick={() => {
              getCartDetails({
                date_check_in: moment(
                  bookingCheckout.dates[0]
                ).format("YYYYMMDD"),
                date_check_out: moment(
                  bookingCheckout.dates[1]
                ).format("YYYYMMDD"),
                brik_id: propertyDetails.brik_id,
                spv_id: localStorage.getItem('spv_id'),
                is_self: false,
                adult: bookingCheckout.members.adults,
                children: bookingCheckout.members.kids,
              })
                .then((result) => {
                  console.log(
                    "RESULT: [getBookingDetails]: ",
                    result[0]
                  );
                  dispatch(setCartDetails(result[0]));
                  history.push("/payment");
                })
                .catch((er) => {
                  notification.enqueueSnackbar(
                    "Brik is already booked",
                    {
                      variant: "error",
                    }
                  );
                  console.log("ownership list ERR: ", er);
                });
              // dispatch(
              // getBookingDetails({
              //   date_check_in: moment(
              //     bookingCheckout.dates[0]
              //   ).format("YYYYMMDD"),
              //   date_check_out: moment(
              //     bookingCheckout.dates[1]
              //   ).format("YYYYMMDD"),
              //   brik_id: propertyDetails.brik_id,
              //   is_self: false,
              //   adult: bookingCheckout.members.adults,
              //   children: bookingCheckout.members.kids,
              // })
              // );
            }}
          >
            NEXT
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default BookingReview;
