import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
  IconButton,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import Image from "../assets/images/BookingImg.png";
import locationIcon from "../assets/icons/locationRed.png";
import coin from "../assets/BrikCoin.svg";
import gold from "../assets/icons/GoldIcon.png";
import heartIcon from "../assets/icons/heart.png";
import Rating from "@material-ui/lab/Rating";
import Add from "../assets/icons/Add.png";
import LessIcon from "../assets/icons/less.png";
import { responsiveStyles } from "../assets/css/generalStyling";
import SearchIcon from "@mui/icons-material/Search";
import Modal from "../component/Modal";
import { Close } from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import moment from "moment";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import BookingReview from "./BookingReview";
import { color } from "@mui/system";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bookingCheckout, getBriks, getSearchBriks } from "../store/actions/bookingActions";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import { Select, MenuItem, FormHelperText, FormControl, InputLabel } from '@material-ui/core';
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  inputTextField: {
    backgroundColor: "#FFFFFF27",
    color: "white",
  },
  inputDateTextField: {
    backgroundColor: "#FFFFFF27",
    color: "white",
    width: "172px",
    borderRadius: "5px",
  },
  disabledInput: {
    "& .MuiFormControl-marginNormal": {
      margin: "0px",
    },
  },
}));

function SearchBooking({ location ,selectedBrikValue}) {
  const [star, setStar] = React.useState(2);
  const history = useHistory();
  const dispatch = useDispatch();
  const notification = useSnackbar();
  const responsive = responsiveStyles();
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState(moment());
  const [value, setValue] = useState();
  const [dateValueIn, setDateValueIn] = useState(moment());
  const [dateValueOut, setDateValueOut] = useState(moment());
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState(false);
  const {
    briks,
    searchBriks,
    bookingCheckout: { dates, brik, members },
    balance
  } = useSelector((state) => {
    return {
      briks: state.booking.briks,
      searchBriks: state.booking.searchBriks,
      bookingCheckout: state.booking.bookingCheckout,
      balance: state.wallet.balance,
    };
  });
  // console.log('searchBriks:', searchBriks, brik)

  const [adults, setAdults] = useState(members.adults);
  const [infants, setInfants] = useState(members.infants);
  const [pets, setPets] = useState(members.pets);
  const [childrens, setChildrens] = useState(members.kids);
  const ModalHeader = () => {
    return (
      <Box
        style={{ zIndex: 1, color: "black" }}
        padding="14px 14px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box></Box>
        <Box>
          <IconButton
            style={{ color: "Black" }}
            onClick={() => {
              setModal(false);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  };

  // Getting briks data for dropdown
  const [briksArr, setBriksArr] = useState([]);
  const [brikPropertyNameArr, setBrikPropertyNameArr] = useState([]);
  const [filter, setFilter] = useState({
    date_check_in: moment(dates[0]).format("YYYYMMDD"),
    date_check_out: moment(dates[1]).format("YYYYMMDD"),
    is_self: value == 'my_properties' ? true : false,
    q: "all"
  })
  //Dropdown value selection usestate
  const [selectedBrik, setSelectedBrik] = useState("");

  React.useEffect(() => {
    if (briksArr.length == 0) {
      let briksHtmlStringArr = []
      let brikPropertyNames = []
      console.log("HERE1");
      if (selectedBrikValue.trim() !== "") {
        filter.q = selectedBrikValue;
      } else {
        filter.q = "All";
      }
      dispatch(getSearchBriks(filter));
      for (let i = 0; i < searchBriks.length; i++) {
        brikPropertyNames.push(searchBriks[i]?.property_name)
        briksHtmlStringArr.push(<MenuItem value={i + 1}>{searchBriks[i]?.property_name}</MenuItem>)
      }
      setBriksArr(briksHtmlStringArr)
      setBrikPropertyNameArr(brikPropertyNames)
      try {
        const value = brikPropertyNames.indexOf(location) + 1
        setSelectedBrik(value);
      } catch (error) {
        console.log("set selected brik data error -", error)
      }
    }
  }, [briksArr, brikPropertyNameArr]);

  React.useEffect(() => {
    setDateValueIn(dates[0]);
    setSearch(location);
    setDateValueOut(dates[1]);
  }, [dates, location]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <>
      <Modal
        width="470px"
        height="200px"
        headerComponent={ModalHeader}
        status={modal}
        saveBtnText="Confirm"
        saveHandler={() => {
          dispatch(
            bookingCheckout({
              key: "members",
              data: {
                adults: adults,
                kids: childrens,
              },
            })
          );
          setModal(false);
        }}
        closeBtnText="Cancel"
        closeHandler={() => {
          setModal(false);
        }}
        borderRadius="none"
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <Box style={{ display: "flex" }}>
            <Box paddingRight="17px" fontWeight="600">
              Adults
            </Box>
            <Box
              onClick={() => {
                let counter = adults - 1;
                if (counter > 0) setAdults(counter);
              }}
            >
              <img src={LessIcon} />
            </Box>
            <Box padding="0 22px">{adults}</Box>
            <Box
              onClick={() => {
                let counter = adults + 1;
                setAdults(counter);
              }}
            >
              <img src={Add} />
            </Box>
          </Box>

          <Box style={{ display: "flex" }}>
            <Box paddingRight="8px">
              <Box fontWeight="600">Kids</Box>
              <Box fontSize="10px">Below 18 age group</Box>
            </Box>
            <Box
              onClick={() => {
                let counter = childrens - 1;
                if (counter >= 0) setChildrens(counter);
              }}
            >
              <img src={LessIcon} />
            </Box>
            <Box padding="0 22px">{childrens}</Box>
            <Box
              onClick={() => {
                let counter = childrens + 1;
                setChildrens(counter);
              }}
            >
              <img src={Add} />
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <Box style={{ display: "flex" }}>
            <Box paddingRight="17px" fontWeight="600">
              Infants
            </Box>
            <Box
              onClick={() => {
                let counter = infants - 1;
                if (counter > 0) setInfants(counter);
              }}
            >
              <img src={LessIcon} />
            </Box>
            <Box padding="0 22px">{infants}</Box>
            <Box
              onClick={() => {
                let counter = infants + 1;
                setInfants(counter);
              }}
            >
              <img src={Add} />
            </Box>
          </Box>

          <Box style={{ display: "flex" }}>
            <Box paddingRight="8px">
              <Box fontWeight="600">Pets</Box>
              {/* <Box fontSize="10px">Below 12 age group</Box> */}
            </Box>
            <Box
              onClick={() => {
                let counter = pets - 1;
                if (counter >= 0) setPets(counter);
              }}
            >
              <img src={LessIcon} />
            </Box>
            <Box padding="0 22px">{pets}</Box>
            <Box
              onClick={() => {
                let counter = pets + 1;
                setPets(counter);
              }}
            >
              <img src={Add} />
            </Box>
          </Box>
        </Box>
      </Modal>
      <Box className={responsive.web}>
        <Box
          style={{
            display: "flex",
            alignItems: "flex-end",
            backgroundColor: "#626871",
            color: "white",
            justifyContent: "space-around",
            padding: "10px 0",
            fontSize: "12px",
          }}
        >
          {/* OLD SEARCH BOX CODE */}
          {/* <Box>
            <Box>Location, Brik name</Box>
            <TextField
              variant="outlined"
              placeholder="Search"
              value={search}
              inputProps={{ className: classes.inputTextField }}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
            />
          </Box> */}
          {/* NEW SEARCH DROPDOWN CODE */}
          <Box>
            <FormControl style={{ width: 200, paddingTop: 5 }}>
              <InputLabel style={{ color: "white" }}>Location, Brik name</InputLabel>
              <Select inputProps={{ className: classes.inputTextField }} value={selectedBrik} onChange={(event) => {
                setSelectedBrik(event.target.value);
                let filters = {
                  date_check_in: moment(dateValueIn).format("YYYYMMDD"),
                  date_check_out: moment(dateValueOut).format("YYYYMMDD"),
                };

                filters.is_self =
                  value == "my_properties" ? true : false;

                setFilter({
                  ...filters,
                  q: "all"
                })
                if (event.target.value) {
                  filters.q = brikPropertyNameArr[event.target.value - 1];
                  setSearch(brikPropertyNameArr[event.target.value - 1]);
                }
              }}
              >
                {briksArr}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Box>Check-in</Box>
              <KeyboardDatePicker
                inputVariant="outlined"
                fullWidth
                className={classes.inputDateTextField}
                id="date-picker-dialog"
                disablePast
                // label="Date picker dialog"
                format="DD-MMM-YYYY"
                TextFieldComponent={(props) => {
                  return (
                    <TextField
                      {...props}
                      InputProps={{
                        ...props.InputProps,
                        className: classes.inputTextField,
                      }}
                    />
                  );
                }}
                value={dateValueIn}
                onChange={(date) => {
                  console.log(
                    "DOB DATE SELECTED: ",
                    moment(date).format("DD-MMM-YYYY")
                  );
                  setDateValueIn(moment(date));
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                  id: `datePicker1`,
                  style: { color: "white" },
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>
          <Box>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Box>Check-out</Box>
              <KeyboardDatePicker
                inputVariant="outlined"
                fullWidth
                id="date-picker-dialog"
                disablePast
                className={classes.inputDateTextField}
                format="DD-MMM-YYYY"
                value={dateValueOut}
                TextFieldComponent={(props) => {
                  return (
                    <TextField
                      {...props}
                      InputProps={{
                        ...props.InputProps,
                        className: classes.inputTextField,
                      }}
                    />
                  );
                }}
                onChange={(date) => {
                  console.log(
                    "DOB DATE SELECTED: ",
                    moment(date).format("DD-MMM-YYYY")
                  );
                  setDateValueOut(moment(date));
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                  id: `datePicker1`,
                  style: { color: "white" },
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>
          <Box>
            <Box>Total members</Box>
            <Box
              style={{
                display: "flex",
                cursor: "pointer",
                backgroundColor: "#FFFFFF27",
                height: "38px",
              }}
              border="1px solid #979797"
              borderRadius="5px"
              padding="10px 30px"
              onClick={() => {
                setModal(true);
              }}
            >
              <Box>{members.adults} Adults</Box>
              <Box style={{ margin: "-2px 6px 0px 6px" }}> | </Box>
              <Box>{members.kids} Kids</Box>
            </Box>
          </Box>
          <Box>
            <Button
              style={{ height: "40px" }}
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                dispatch(
                  bookingCheckout({
                    key: "members",
                    data: {
                      adults: adults,
                      kids: childrens,
                    },
                  })
                );

                dispatch(
                  bookingCheckout({
                    key: "dates",
                    data: [dateValueIn, dateValueOut],
                  })
                );

                let filters = {
                  date_check_in: moment(dateValueIn).format("YYYYMMDD"),
                  date_check_out: moment(dateValueOut).format("YYYYMMDD"),
                };
                filters.is_self = value == "my_properties" ? true : false;
                setFilter({
                  ...filters,
                  q: "all"
                })

                filters.q = search;

                dispatch(getBriks(filters));
              }}
            >
              Update Search
            </Button>
          </Box>
        </Box>

        <Box padding="20px">
          <Grid container spacing={4}>
            {briks.map((brik, index) => (
              <Grid item xs={4} key={index}>
                <Card
                  style={{
                    padding: "10px",
                    position: "relative",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (balance > 0) {
                      console.log("BRIKTITT: ", brik);
                      localStorage.setItem('spv_id', brik?.spv_id)
                      dispatch(
                        bookingCheckout({
                          key: "brik",
                          data: brik,
                        })
                      );

                      let filters = {
                        date_check_in: moment(dateValueIn).format("YYYY-MM-DD"),
                        date_check_out: moment(dateValueOut).format("YYYY-MM-DD"),
                      };

                      var queryString = Object.keys(filters)
                        .map(function (key) {
                          return key + "=" + filters[key];
                        })
                        .join("&");

                      history.push(`/booking/${brik.id}?${queryString}`);
                    }
                    else {
                      notification.enqueueSnackbar(
                        "You cannot make your bookings as your wallet balance is 0 or wallet is not created yet. Please contact admin.",
                        {
                          variant: "error",
                        }
                      );
                    }
                  }}
                >
                  <Box
                    style={{
                      boxShadow: "inset 0px 70px 70px #090909",
                      height: "280px",
                      backgroundImage: `url(${brik.brik_cover_image})`,
                      backgroundSize: "100% 280px",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <Box>
                      <img
                        src={brik.brik_cover_image}
                        style={{
                          height: "100%",
                          width: "100%",
                          visibility: "hidden",
                        }}
                      />
                    </Box>
                  </Box>
                  <Box position="absolute" top="15px" right="17px">
                    {brik.property_type == "Gold leisure" && <img src={gold} />}
                  </Box>
                  <Box position="absolute" top="18px" right="133px">
                    {/* <img src={heartIcon} /> */}
                  </Box>
                  {brik.total_review > 0 && (
                    <Box
                      component="fieldset"
                      mb={3}
                      borderColor="transparent"
                      position="absolute"
                      top="5px"
                      color="white"
                    >
                      <Rating
                        name="read-only"
                        value={brik.total_review}
                        readOnly
                      />
                      <Box>({brik.average_review}) reviews</Box>
                    </Box>
                  )}

                  <Box pading="0 10px">
                    <Box color="#4A8EAB" fontSize="16px" fontWeight="600">
                      {brik.property_name}
                    </Box>

                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Box>
                          <img src={locationIcon} style={{ height: "16px" }} />
                        </Box>
                        <Box paddingLeft="2px">{brik.location}</Box>
                      </Box>

                      <Box style={{ display: "flex", flexDirection: "column" }}>
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          <Box>
                            <img src={coin} style={{ height: "13px" }} />
                          </Box>
                          <Box
                            fontSize="12px"
                            fontWeight="600"
                            color="#8B7862"
                            paddingLeft="5px"
                          >
                            {brik.per_night_coin ? brik.per_night_coin : 0}{" "}
                            coin/Night
                          </Box>
                        </Box>
                        <Box fontSize="12px">
                          {brik.per_night_inr ? brik.per_night_inr : 0} night
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Box className={responsive.app}>
        <Box
          style={{
            backgroundColor: "#626871",
            color: "white",
            justifyContent: "space-around",
            padding: "10px 0",
            fontSize: "12px",
          }}
        >
          <Box display="flex" alignItems="center" padding="2%">
            {/* <Box>Location, Brik name</Box> */}
            <Box
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowBackIos />
            </Box>
            <Box>{brik.property_name}</Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingLeft="2%"
          >
            <Box>{moment(dateValueIn).format("DD MMM")}</Box>
            <Box>-</Box>
            <Box>{moment(dateValueOut).format("DD MMM")}</Box>
            <Box>{members.adults} Adults</Box>
            <Box style={{ margin: "-2px 6px 0px 6px" }}> | </Box>
            <Box>{members.kids} Kids</Box>
            <Box>
              <Button
                style={{ height: "40px" }}
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => {
                  dispatch(
                    bookingCheckout({
                      key: "members",
                      data: {
                        adults: adults,
                        kids: childrens,
                      },
                    })
                  );

                  dispatch(
                    bookingCheckout({
                      key: "dates",
                      data: [dateValueIn, dateValueOut],
                    })
                  );

                  let filters = {
                    date_check_in: moment(dateValueIn).format("YYYYMMDD"),
                    date_check_out: moment(dateValueOut).format("YYYYMMDD"),
                  };

                  filters.q = brik.property_name;

                  dispatch(getBriks(filters));
                }}
              >
                <SearchIcon />
              </Button>
            </Box>
          </Box>
        </Box>
        <Box padding="2%">
          <Grid container>
            {briks.map((brik) => (
              <Grid item xs={12}>
                <Card
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    marginBottom: "20px",
                  }}
                  onClick={() => {
                    if (balance > 0) {
                      console.log("BRIKTITT: ", brik);
                      localStorage.setItem('spv_id', brik?.spv_id)
                      dispatch(
                        bookingCheckout({
                          key: "brik",
                          data: brik,
                        })
                      );

                      let filters = {
                        date_check_in: moment(dateValueIn).format("YYYY-MM-DD"),
                        date_check_out: moment(dateValueOut).format("YYYY-MM-DD"),
                      };

                      var queryString = Object.keys(filters)
                        .map(function (key) {
                          return key + "=" + filters[key];
                        })
                        .join("&");

                      history.push(`/booking/${brik.id}?${queryString}`);
                    }
                    else {
                      notification.enqueueSnackbar(
                        "You cannot make your bookings as your wallet balance is 0 or wallet is not created yet. Please contact admin.",
                        {
                          variant: "error",
                        }
                      );
                    }
                  }}
                >
                  <Box
                    style={{
                      boxShadow: "inset 0px 70px 70px #090909",
                      borderRadius: "5px",
                      backgroundImage: `url(${brik.brik_cover_image})`,
                      backgroundSize: "100%",
                      backgroundRepeat: "no-repeat",
                      maxHeight: "150px",
                    }}
                  >
                    <Box>
                      <img
                        src={brik.brik_cover_image}
                        style={{
                          height: "60%",
                          width: "100%",
                          visibility: "hidden",
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    position="absolute"
                    top="15px"
                    left="17px"
                    style={{
                      fontWeight: 700,
                      fontSize: "20px",
                      lineHeight: "20px",
                      color: "white",
                    }}
                  >
                    {brik.property_name}
                  </Box>
                  <Box
                    position="absolute"
                    top="40px"
                    left="17px"
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "14px",
                      color: "white",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {brik?.location?.substring(0, brik.location.indexOf(","))}
                  </Box>
                  <Box position="absolute" top="15px" right="17px">
                    {brik.property_type == "Gold leisure" && <img src={gold} />}
                  </Box>
                  <Box position="absolute" top="18px" right="133px">
                    {/* <img src={heartIcon} /> */}
                  </Box>
                  <Box
                    style={{ display: "flex", flexDirection: "column" }}
                    position="absolute"
                    bottom="5px"
                    right="17px"
                  >
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Box>
                        <img src={coin} style={{ height: "13px" }} />
                      </Box>
                      <Box
                        fontSize="14px"
                        fontWeight="600"
                        color="white"
                        paddingLeft="5px"
                      >
                        {brik.per_night_coin ? brik.per_night_coin : 0}{" "}
                        coin/Night
                      </Box>
                    </Box>
                    <Box fontSize="14px" color="white">
                      ₹{"  "}
                      {brik.per_night_inr ? brik.per_night_inr : 0}/Night
                    </Box>
                  </Box>
                  {brik.total_review > 0 && (
                    <Box
                      component="fieldset"
                      //mb={3}
                      borderColor="transparent"
                      position="absolute"
                      bottom="2px"
                      color="white"
                      display="flex"
                    >
                      <Rating
                        name="read-only"
                        value={brik.total_review}
                        readOnly
                      />
                      <Box>({brik.average_review}) reviews</Box>
                    </Box>
                  )}

                  {/* <Box pading="0 10px">
                    <Box color="#4A8EAB" fontSize="16px" fontWeight="600">
                      {brik.property_name}
                    </Box>

                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                       <Box style={{ display: "flex", alignItems: "center" }}>
                        <Box>
                          <img src={location} style={{ height: "16px" }} />
                        </Box>
                        <Box paddingLeft="2px">{brik.location}</Box>
                      </Box> 

                      <Box style={{ display: "flex", flexDirection: "column" }}>
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          <Box>
                            <img src={coin} style={{ height: "13px" }} />
                          </Box>
                          <Box
                            fontSize="12px"
                            fontWeight="600"
                            color="#8B7862"
                            paddingLeft="5px"
                          >
                            {brik.per_night_coin ? brik.per_night_coin : 0}{" "}
                            coin/Night
                          </Box>
                        </Box>
                        <Box fontSize="12px">
                          {brik.per_night_inr ? brik.per_night_inr : 0} night
                        </Box>
                      </Box>
                    </Box>
                  </Box> */}
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default SearchBooking;
